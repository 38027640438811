import {
  faCubesStacked,
  faFolderOpen,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@themesberg/react-bootstrap";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export default function Item() {
  const [transactions, setTransactions] = useState([]);
  const [prices, setPrices] = useState([]);
  const [day, setDay] = useState([]);
  const [day12, setDay12] = useState([]);
  const [day75, setDay75] = useState([]);
  const [day300, setDay300] = useState([]);

  const [distance, setDistance] = useState(6480000);

  const [button, setButton] = useState(2);

  const [item, setItem] = useState({
    item_id: 21028,
    name: "Dragon harpoon",
    volume: 332,
    image: "Dragon harpoon.png",
    bought: 0,
  });

  console.log(prices);

  const location = useLocation();

  const itemID = location.state ? location.state : item.item_id;

  useEffect(() => {
    fetch("https://www.ge-trader.com/item/" + itemID)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setTransactions(data["transactions"]);
        // setPrices(data["prices"]);
        setItem(data["item"]);
      });
    fetch(
      "https://prices.runescape.wiki/api/v1/osrs/timeseries?timestep=5m&id=" +
        itemID
    )
      .then((response) => response.json())
      .then((data) =>
        setDay(
          data["data"].filter(
            (obj) => obj.avgLowPrice !== null && obj.avgHighPrice !== null
          )
        )
      );
    fetch(
      "https://prices.runescape.wiki/api/v1/osrs/timeseries?timestep=1h&id=" +
        itemID
    )
      .then((response) => response.json())
      .then((data) =>
        setDay12(
          data["data"].filter(
            (obj) => obj.avgLowPrice !== null && obj.avgHighPrice !== null
          )
        )
      );
    fetch(
      "https://prices.runescape.wiki/api/v1/osrs/timeseries?timestep=6h&id=" +
        itemID
    )
      .then((response) => response.json())
      .then((data) => {
        setPrices(
          data["data"].filter(
            (obj) => obj.avgLowPrice !== null && obj.avgHighPrice !== null
          )
        );
        setDay75(
          data["data"].filter(
            (obj) => obj.avgLowPrice !== null && obj.avgHighPrice !== null
          )
        );
      });
    fetch(
      "https://prices.runescape.wiki/api/v1/osrs/timeseries?timestep=24h&id=" +
        itemID
    )
      .then((response) => response.json())
      .then((data) =>
        setDay300(
          data["data"].filter(
            (obj) => obj.avgLowPrice !== null && obj.avgHighPrice !== null
          )
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col
          className="d-flex justify-content-center align-items-center mb-3"
          sm={3}
        >
          <img
            src={
              "https://oldschool.runescape.wiki/images/" +
              encodeURI(
                item.image.slice(0, item.image.length - 4).replaceAll(" ", "_")
              ) +
              "_detail.png"
            }
            alt="logo"
            height="210px"
          />
        </Col>

        <Col sm={9} className="mb-3">
          <Card border="light" className="shadow-sm">
            <Card.Body>
              <div className="d-flex align-items-center justify-content-between border-bottom border-light pb-3">
                <div>
                  <h6>
                    <img
                      src={
                        "https://oldschool.runescape.wiki/images/" +
                        encodeURI(item.image.replaceAll(" ", "_"))
                      }
                      alt="logo"
                      style={{ marginRight: "10px" }}
                    />
                    {item.name}
                  </h6>
                </div>
                <div>
                  <Card.Link href="#" className="text-primary fw-bold">
                    Item ID: {" " + itemID}{" "}
                    {/* <FontAwesomeIcon icon={faChartLine} className="ms-2" /> */}
                  </Card.Link>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between border-bottom border-light py-3">
                <div>
                  <h6 className="mb-0">
                    <FontAwesomeIcon
                      icon={faCubesStacked}
                      className="icon icon-xs me-3"
                    />
                    Volume:{" " + item.volume}
                  </h6>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between pt-3">
                <div>
                  <h6 className="mb-0">
                    <FontAwesomeIcon
                      icon={faMoneyBill}
                      className="icon icon-xs me-3"
                    />
                    {item.bought ? "Bought" : "Not Bought"}
                  </h6>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Card border="light" className="shadow-sm">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5>Price And Buy History</h5>
            </Col>
            <Col className="text-end">
              <Button
                variant={"outline-dark" + (button === 1 ? " active" : "")}
                className="m-1"
                onClick={() => {
                  setPrices(day300);
                  setButton(1);
                  setDistance(25920000);
                }}
              >
                300 Day
              </Button>
              <Button
                variant={"outline-dark" + (button === 2 ? " active" : "")}
                className="m-1"
                onClick={() => {
                  setPrices(day75);
                  setButton(2);
                  setDistance(6480000);
                }}
              >
                75 Day
              </Button>
              <Button
                variant={"outline-dark" + (button === 3 ? " active" : "")}
                className="m-1"
                onClick={() => {
                  setPrices(day12);
                  setButton(3);
                  setDistance(1036800);
                }}
              >
                12 Day
              </Button>
              <Button
                variant={"outline-dark" + (button === 4 ? " active" : "")}
                className="m-1"
                onClick={() => {
                  setPrices(day);
                  setButton(4);
                  setDistance(86400);
                }}
              >
                1 Day
              </Button>
            </Col>
          </Row>
        </Card.Header>
        {
          // @ts-ignore
          <ReactApexChart
            options={{
              chart: {
                height: 350,
                type: "line",
              },
              markers: {
                size: [5, 5, 0, 0],
              },
              legend: {
                show: true,
              },
              xaxis: {
                type: "datetime",
              },
            }}
            series={[
              {
                name: "Buys",
                type: "scatter",
                //2.14, 2.15, 3.61, 4.93, 2.4, 2.7, 4.2, 5.4, 6.1, 8.3
                data: transactions
                  .filter(
                    (obj) =>
                      obj.buy_time > new Date().getTime() / 1000 - distance
                  )
                  .map((obj) => ({
                    x: obj.buy_time * 1000,
                    y: obj.spent,
                  })),
              },
              {
                name: "Sells",
                type: "scatter",
                //2.14, 2.15, 3.61, 4.93, 2.4, 2.7, 4.2, 5.4, 6.1, 8.3
                data: transactions
                  .filter(
                    (obj) =>
                      obj.sell_time !== null &&
                      obj.sell_time > new Date().getTime() / 1000 - distance
                  )
                  .map((obj) => ({
                    x: obj.sell_time * 1000,
                    y: obj.sold,
                  })),
              },
              {
                name: "Lows",
                type: "line",
                data: prices.map((obj) => ({
                  x: obj.timestamp * 1000,
                  y: obj.avgLowPrice,
                })),
              },
              {
                name: "Highs",
                type: "line",
                data: prices.map((obj) => ({
                  x: obj.timestamp * 1000,
                  y: obj.avgHighPrice,
                })),
              },
            ]}
            type="line"
            height="500"
          />
        }
      </Card>
    </Container>
  );
}
