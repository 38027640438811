import React, { useEffect, useState } from "react";

import "../scss/volt.scss";

import { RecentTransactionsTable } from "./RecentTransactionsTable";
import { Col, Container, Row, Card } from "@themesberg/react-bootstrap";
import ReactApexChart from "react-apexcharts";

export default function HomePage() {
  const [buys, setBuys] = useState([]);
  const [sells, setSells] = useState([]);
  const [profits, setProfits] = useState([]);
  const [sellPrices, setSellPrices] = useState(0);
  const [buyPrices, setBuyPrice] = useState(0);

  useEffect(() => {
    fetch("https://www.ge-trader.com/homepage/buys")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setBuys(data["buys"]);
      });
    fetch("https://www.ge-trader.com/homepage/sells")
      .then((response) => response.json())
      .then((data) => {
        setSells(data["sells"]);
      });
    fetch("https://www.ge-trader.com/homepage/profits")
      .then((response) => response.json())
      .then((data) => {
        setProfits(data["profits"]);
      });
    // fetch("https://www.ge-trader.com/homepage/sell_prices")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setSellPrices(data["sell_prices"]);
    //   });
    // fetch("https://www.ge-trader.com/homepage/buy_prices")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setBuyPrice(data["buy_prices"]);
    //   });
    fetch("https://prices.runescape.wiki/api/v1/osrs/latest")
      .then((response) => response.json())
      .then((data) => {
        setSellPrices(data["data"]);
        setBuyPrice(data["data"]);
      });
  }, []);

  const buyObjs =
    sellPrices !== 0
      ? Object.values(buys).map((obj) => ({
          volume: obj.volume,
          item_id: obj.item_id,
          name: obj.name,
          date: obj.buy_time,
          bounceIcon: sellPrices[obj.item_id].high >= obj.spent,
          bounceTxtColor: sellPrices[obj.item_id].high >= obj.spent,
          status:
            sellPrices[obj.item_id].high >= obj.spent
              ? 100 * (sellPrices[obj.item_id].high / obj.spent - 1)
              : -1 * 100 * (1 - sellPrices[obj.item_id].high / obj.spent),
        }))
      : [];

  const sellObjs =
    buyPrices !== 0
      ? Object.values(sells).map((obj) => ({
          volume: obj.volume,
          item_id: obj.item_id,
          name: obj.name,
          date: obj.sell_time,
          bounceIcon: buyPrices[obj.item_id].low > obj.sold,
          bounceTxtColor: buyPrices[obj.item_id].low <= obj.sold,
          status:
            buyPrices[obj.item_id].low >= obj.sold
              ? 100 * (buyPrices[obj.item_id].low / obj.sold - 1)
              : 100 * -1 * (1 - buyPrices[obj.item_id].low / obj.sold),
        }))
      : [];

  const [volume, setVolume] = useState(50);

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="mb-3">
            <RecentTransactionsTable
              title={"Buy"}
              rowObjs={buyObjs.filter(
                (obj) =>
                  obj.volume > volume && sellPrices[obj.item_id].high > 1000000
              )}
            />
          </Col>
          <Col className="mb-3">
            <RecentTransactionsTable
              title={"Sell"}
              rowObjs={sellObjs.filter(
                (obj) =>
                  obj.volume > volume && buyPrices[obj.item_id].low > 1000000
              )}
            />
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Card border="light" className="shadow-sm">
          <Card.Header>
            <h5>Theoretical Profit Over Time</h5>
          </Card.Header>
          {
            // @ts-ignore
            <ReactApexChart
              options={{
                chart: {
                  height: 350,
                  type: "line",
                },
                fill: {
                  type: "solid",
                },
                markers: {
                  size: [6, 6],
                },
                tooltip: {
                  shared: false,
                  intersect: true,
                },
                legend: {
                  show: true,
                },
                xaxis: {
                  type: "datetime",
                  // tickAmount: 12,
                },
              }}
              series={[
                {
                  name: "Line",
                  type: "line",
                  data: Object.values(profits).map((obj) => ({
                    x: obj.time * 1000,
                    y: obj.profit,
                  })),
                  // .slice(0, 100),
                },
              ]}
              type="line"
              height="500"
            />
          }
        </Card>
      </Container>
    </>
  );
}
