import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { Card, Table } from "@themesberg/react-bootstrap";
import { useNavigate } from "react-router-dom";

export const RecentTransactionsTable = ({ title, rowObjs }) => {
  const TableRow = ({ item_id, name, date, green, up, status }) => {
    const bounceIcon = up ? faArrowUp : faArrowDown;
    const bounceTxtColor = green ? "text-success" : "text-danger";
    let navigate = useNavigate();

    function timeSince(date) {
      var seconds = Math.floor((Date.now() - date) / 1000);

      var interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) === 1
          ? Math.floor(interval) + " years"
          : Math.floor(interval) + " years";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) === 1
          ? Math.floor(interval) + " month"
          : Math.floor(interval) + " months";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) === 1
          ? Math.floor(interval) + " day"
          : Math.floor(interval) + " days";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) === 1
          ? Math.floor(interval) + " hour"
          : Math.floor(interval) + " hours";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) === 1
          ? Math.floor(interval) + " minute"
          : Math.floor(interval) + " minutes";
      }
      return Math.floor(interval) === 1
        ? Math.floor(interval) + " second"
        : Math.floor(seconds) + " seconds";
    }

    return (
      <tr
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/Item", { state: item_id })}
      >
        <th scope="row">{name}</th>
        <td>
          {new Date(date * 1000).toLocaleDateString("en-US") +
            " " +
            new Date(date * 1000).toLocaleTimeString("en-US")}
          <div>{timeSince(date * 1000)} ago</div>
        </td>
        <td>
          <FontAwesomeIcon
            icon={bounceIcon}
            className={`${bounceTxtColor} me-3`}
          />
          {status.toFixed(2)}%
        </td>
      </tr>
    );
  };

  return (
    <Card
      border="light"
      style={{
        maxHeight: "479px",
      }}
    >
      <Card.Header>
        <h5>Recent {title}s</h5>
      </Card.Header>

      <Table
        responsive
        className="align-items-center table-flush"
        hover
        style={{ borderCollapse: "separate", borderSpacing: 0 }}
      >
        {/* https://stackoverflow.com/questions/21168521/table-fixed-header-and-scrollable-body */}
        <thead
          className="thead-light"
          style={{
            position: "sticky",
            top: 0,
            borderBottom: "0.125rem",
          }}
        >
          <tr>
            <th scope="col">Item name</th>
            <th scope="col">{title === "Buy" ? "Bought" : "Sold"} on</th>
            <th scope="col">Price Since {title}</th>
          </tr>
        </thead>
        <tbody style={{ borderTop: "none" }}>
          {rowObjs.length > 0 &&
            rowObjs.map((obj, idx) => (
              <TableRow
                key={obj.name}
                item_id={obj.item_id}
                name={obj.name}
                date={obj.date}
                green={obj.bounceTxtColor}
                up={obj.bounceIcon}
                status={obj.status}
              />
            ))}
        </tbody>
      </Table>
    </Card>
  );
};
