import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Form, InputGroup, Container } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AllItemsTable } from "./AllItemsTable";
import { useEffect, useState } from "react";

export default function AllItems() {
  const [dataPoints, setDataPoints] = useState({ item_ids: {} });
  const [volume, setVolume] = useState(50);

  const [sellPrices, setSellPrices] = useState(0);
  const [buyPrices, setBuyPrice] = useState(0);

  useEffect(() => {
    fetch("https://www.ge-trader.com/getItems")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setDataPoints(() => data);
      });
    // fetch("https://www.ge-trader.com/homepage/sell_prices")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setSellPrices(data["sell_prices"]);
    //   });
    // fetch("https://www.ge-trader.com/homepage/buy_prices")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setBuyPrice(data["buy_prices"]);
    //   });
    fetch("https://prices.runescape.wiki/api/v1/osrs/latest")
      .then((response) => response.json())
      .then((data) => {
        setSellPrices(data["data"]);
        setBuyPrice(data["data"]);
      });
  }, []);

  const tableObjs = Object.values(dataPoints["item_ids"]).map((obj) =>
    sellPrices !== 0 && sellPrices !== 0
      ? obj.bought === 1 && sellPrices[obj.item_id].high !== null
        ? {
            bought: obj.bought,
            volume: obj.volume,
            item_id: obj.item_id,
            name: obj.name,
            date: dataPoints["buys"][obj.item_id].buy_time,
            bounceIcon:
              sellPrices[obj.item_id].high >=
              dataPoints["buys"][obj.item_id].spent,
            bounceTxtColor:
              sellPrices[obj.item_id].high >=
              dataPoints["buys"][obj.item_id].spent,
            status:
              sellPrices[obj.item_id].high >=
              dataPoints["buys"][obj.item_id].spent
                ? 100 *
                  (sellPrices[obj.item_id].high /
                    dataPoints["buys"][obj.item_id].spent -
                    1)
                : -1 *
                  100 *
                  (1 -
                    sellPrices[obj.item_id].high /
                      dataPoints["buys"][obj.item_id].spent),
          }
        : dataPoints["sells"][obj.item_id] !== undefined &&
          buyPrices[obj.item_id].low !== null
        ? {
            bought: obj.bought,
            volume: obj.volume,
            item_id: obj.item_id,
            name: obj.name,
            date: dataPoints["sells"][obj.item_id].sell_time,
            bounceIcon:
              buyPrices[obj.item_id].low >
              dataPoints["sells"][obj.item_id].sold,
            bounceTxtColor:
              buyPrices[obj.item_id].low <=
              dataPoints["sells"][obj.item_id].sold,
            status:
              buyPrices[obj.item_id].low >=
              dataPoints["sells"][obj.item_id].sold
                ? 100 *
                  (buyPrices[obj.item_id].low /
                    dataPoints["sells"][obj.item_id].sold -
                    1)
                : 100 *
                  -1 *
                  (1 -
                    buyPrices[obj.item_id].low /
                      dataPoints["sells"][obj.item_id].sold),
          }
        : {
            bought: obj.bought,
            volume: obj.volume,
            item_id: obj.item_id,
            name: obj.name,
            date: 0,
            bounceIcon: true,
            bounceTxtColor: true,
            status: 0,
          }
      : {
          bought: obj.bought,
          volume: obj.volume,
          item_id: obj.item_id,
          name: obj.name,
          date: 0,
          bounceIcon: true,
          bounceTxtColor: true,
          status: 0,
        }
  );

  return (
    <>
      <Container fluid>
        <div className="d-flex align-items-center">
          <Form.Group id="topbarSearch">
            <InputGroup className="input-group-merge search-bar">
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Filter By Volume"
                onChange={(event) => {
                  let myValue = event.target.value;
                  setVolume(myValue.length > 0 ? parseInt(myValue) : 50);
                }}
              />
            </InputGroup>
          </Form.Group>
        </div>
        <br />
        <AllItemsTable
          rowObjs={tableObjs.filter((obj) => obj.volume > volume)}
        />
        <br />
      </Container>
    </>
  );
}
