import React from "react";
import { createRoot } from "react-dom/client";

import reportWebVitals from "./reportWebVitals";

import HomePage from "./components/HomePage";

import { Navbar, Nav, Container } from "@themesberg/react-bootstrap";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import AllItems from "./components/AllItems";
import Item from "./components/Item";
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <Navbar bg="light" variant="light">
      <Container className="position-relative" style={{ margin: 0 }}>
        <Navbar.Brand href="/">GE Trader</Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/AllItems">All Items</Nav.Link>
        </Nav>
      </Container>
    </Navbar>
    <br />
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/AllItems" element={<AllItems />} />
      <Route path="/Item" element={<Item />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
